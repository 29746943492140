<nav
  class="navbar navbar-expand-lg fixed-top navbar-transparent"
  color-on-scroll="100"
  id="navbar-top"
>
  <div class="container">
    <div class="navbar-translate">
      <a class="navbar-brand" href="#" placement="bottom" tooltip="G'day 🦘">
        <span> KB• </span> Kevin Bueno
      </a>
      <button
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
        class="navbar-toggler navbar-toggler"
        [attr.aria-expanded]="!isCollapsed"
        (click)="isCollapsed = !isCollapsed"
        id="navigation"
        type="button"
      >
        <span class="navbar-toggler-bar bar1"> </span>
        <span class="navbar-toggler-bar bar2"> </span>
        <span class="navbar-toggler-bar bar3"> </span>
      </button>
    </div>
    <div
      class="navbar-collapse justify-content-end"
      [collapse]="isCollapsed"
      id="navigation"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand"><a> KB• </a></div>
          <div class="col-6 collapse-close text-right">
            <button
              aria-controls="navigation-index"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="navigation"
              type="button"
            >
              <i class="tim-icons icon-simple-remove"> </i>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="https://www.notion.so/Projects-5646077b312743e3830d6d51fa491d16?pvs=4"
            placement="bottom"
            target="_blank"
            tooltip="Look at my latest projects"
          >
            <i class="fa fa-book"> </i>
            <p class="d-lg-none d-xl-none">Projects</p>
          </a>
        </li>
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="https://twitter.com/aristotekean"
            placement="bottom"
            target="_blank"
            tooltip="Follow me on X"
          >
            <i class="fab fa-x-twitter"> </i>
            <p class="d-lg-none d-xl-none">X</p>
          </a>
        </li>
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="https://www.instagram.com/aristotekean/"
            placement="bottom"
            target="_blank"
            tooltip="Follow me on Instagram"
          >
            <i class="fab fa-instagram"> </i>
            <p class="d-lg-none d-xl-none">Instagram</p>
          </a>
        </li>
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="https://www.linkedin.com/in/kevin-bueno-7122b5a0/"
            placement="bottom"
            target="_blank"
            tooltip="Follow me on Linkedin"
          >
            <i class="fab fa-linkedin-in"> </i>
            <p class="d-lg-none d-xl-none">Linkedin</p>
          </a>
        </li>
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="https://medium.com/@aristotekean"
            placement="bottom"
            target="_blank"
            tooltip="Follow me on Medium"
          >
            <i class="fab fa-medium"> </i>
            <p class="d-lg-none d-xl-none">Medium</p>
          </a>
        </li>
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="https://github.com/aristotekean"
            placement="bottom"
            target="_blank"
            tooltip="Follow me on Github"
          >
            <i class="fa-brands fa-github"></i>
            <p class="d-lg-none d-xl-none">Github</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="wrapper">
  <div class="page-header">
    <img class="dots" src="assets/img/dots.png" />

    <img class="path" src="assets/img/path4.png" />

    <div class="container align-items-center">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <h1 class="profile-title text-left">Introduction</h1>
          <h5 class="text-on-back">01</h5>
          <p class="profile-description">
            As a skilled software engineer, I have mastered designing,
            developing, and testing software applications using technologies
            such as Angular/Ionic, TypeScript, Python and Go. I am experienced
            in methodologies like Scrum and enjoy collaborating with teams to
            create software that meets user requirements and business needs. My
            passion lies in driving innovation and building the digital tools
            and applications that we use in our daily lives.
          </p>
          <div class="btn-wrapper profile pt-3">
            <a
              href="https://www.linkedin.com/in/kevin-bueno-7122b5a0/"
              target="_blank"
              class="btn btn-simple btn-danger mr-1"
            >
              <i class="fab fa-linkedin-in"> </i> Read more
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
          <div class="card card-coin card-plain">
            <div class="card-header text-center">
              <img
                class="img-center img-fluid rounded-circle"
                src="assets/img/kevin.webp"
              />
              <h1 class="title">Kevin Bueno</h1>
              <h4 class="h3 mt-0 mb-2">Software Engineer</h4>
              <h4 class="h4 mt-0">
                UTC/GMT +10 hours | 🇦🇺 English , 🇨🇴 Spanish and 🇯🇵 Japanese in
                progress ...
              </h4>
              <a
                href="https://medium.com/@aristotekean"
                target="_blank"
                class="btn btn-simple btn-warning mr-1"
              >
                <i class="fab fa-medium"> </i>
                Learn together!
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-6">
          <div class="row justify-content-between align-items-center">
            <carousel [showIndicators]="true">
              <slide>
                <img
                  alt="First slide"
                  class="d-block"
                  src="assets/img/denys.jpg"
                />

                <div class="carousel-caption d-none d-md-block">
                  <h5>
                    “When something is important enough, you do it even if the
                    odds aren’t in your favor.” — Elon Musk
                  </h5>
                </div>
              </slide>
              <slide>
                <img
                  alt="Second slide"
                  class="d-block"
                  src="assets/img/fabien-bazanegue.jpg"
                />

                <div class="carousel-caption d-none d-md-block">
                  <h5>"No attack, no chance" - Satō Takuma</h5>
                </div>
              </slide>
              <slide>
                <img
                  alt="Third slide"
                  class="d-block"
                  src="assets/img/mark-finn.jpg"
                />

                <div class="carousel-caption d-none d-md-block">
                  <h5>
                    "Somos lo que hacemos repetidamente. La excelencia, no es un
                    acto, sino un hábito ” - Aristóteles
                  </h5>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
        <div class="col-md-5">
          <h1 class="profile-title text-left">Projects</h1>
          <h5 class="text-on-back">02</h5>
          <p class="profile-description text-left">
            I am truly enthusiastic about crafting dynamic and captivating user
            interfaces that provide smooth and enjoyable experiences. With a
            solid history, I have successfully constructed robust and scalable
            applications for a diverse range of clients spanning various
            industries building <strong>web applications</strong>,
            <strong>dashboards</strong> and <strong>mobile apps</strong>.
          </p>
          <div class="btn-wrapper pt-3">
            <a
              class="btn btn-simple btn-info"
              href="https://www.notion.so/Projects-5646077b312743e3830d6d51fa491d16?pvs=4"
              target="_blank"
            >
              <i class="fa fa-book mr-1"></i>Check it!
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="card card-plain">
            <div class="card-header">
              <h1 class="profile-title text-left">Contact</h1>
              <h5 class="text-on-back">03</h5>
            </div>
            <div class="card-body">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label> Subject </label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Say hello!"
                        formControlName="subject"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label> Email address </label>
                      <input
                        class="form-control"
                        placeholder="kaizen@email.com"
                        formControlName="email"
                        type="email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label> Message </label>
                      <textarea
                        class="form-control"
                        placeholder="Hello there!"
                        formControlName="message"
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <a
                  class="btn btn-danger btn-round float-right"
                  placement="right"
                  href="{{
                    'mailto:kean.solano@gmail.com?cc=' +
                      emailField.value +
                      '&subject=' +
                      subjectField.value +
                      '&body=' +
                      messageField.value
                  }}"
                  type="submit"
                >
                  Send text
                </a>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-4 ml-auto">
          <div class="info info-horizontal">
            <div class="icon icon-danger">
              <i class="tim-icons icon-square-pin"> </i>
            </div>
            <div class="description">
              <h4 class="info-title">Location</h4>
              <p>
                Stanmore, 2048, <br />

                Great Sydney, <br />

                Australia
              </p>
            </div>
          </div>
          <div class="info info-horizontal">
            <div class="icon icon-danger">
              <i class="tim-icons icon-mobile"> </i>
            </div>
            <div class="description">
              <h4 class="info-title">Get in touch</h4>
              <p>
                Kevin Bueno <br />
                kean.solano&#64;gmail.com <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="title">KB•</h1>
          <div class="btn-wrapper profile">
            <a
              class="btn btn-icon btn-neutral btn-round btn-simple mr-1"
              href="https://twitter.com/aristotekean"
              target="_blank"
            >
              <i class="fab fa-x-twitter"> </i>
            </a>
            <a
              class="btn btn-icon btn-neutral btn-round btn-simple mr-1"
              href="https://www.instagram.com/aristotekean/"
              target="_blank"
            >
              <i class="fab fa-instagram"> </i>
            </a>
            <a
              class="btn btn-icon btn-neutral btn-round btn-simple"
              href="https://www.linkedin.com/in/kevin-bueno-7122b5a0/"
              target="_blank"
            >
              <i class="fab fa-linkedin-in"> </i>
            </a>
            <a
              class="btn btn-icon btn-neutral btn-round btn-simple"
              href="https://medium.com/@aristotekean"
              target="_blank"
            >
              <i class="fab fa-medium"> </i>
            </a>
            <a
              class="btn btn-icon btn-neutral btn-round btn-simple"
              href="https://github.com/aristotekean"
              target="_blank"
            >
              <i class="fab fa-github"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
