import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: "app-profilepage",
  templateUrl: "profilepage.component.html"
})
export class ProfilepageComponent implements OnInit, OnDestroy {

  form!: FormGroup;

  isCollapsed = true;

  constructor(private formBuilder: FormBuilder) { this.buildForm(); }

  get subjectField() {
    return this.form?.get('subject');
  }

  get emailField() {
    return this.form?.get('email');
  }

  get messageField() {
    return this.form?.get('message');
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      subject: [''],
      email: [''],
      message: [''],
    });
  };

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("profile-page");
  };
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("profile-page");
  };
}
